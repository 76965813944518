html, body, #root, #container {
  position: fixed;
  height: 100%;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

#container > * {
  width: 100%;
  max-width: 1280px;
  margin: auto;
}
